@import "@/styles/shared";
.scrollContainer {
  position: relative;
  width: 100%;
  opacity: 0;

  &.disableScroll {
    max-height: 100svh;
    overflow: hidden;
  }

  [data-is-body-scroller='false'] & {
    height: 100svh;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.scrollContainerInner {
  display: block;
  z-index: 2;
  position: relative;

  &.hasNextItem {
    margin-bottom: var(--next-item-height);
  }
}

.nextProduct {
  z-index: 1;
}
